import "./App.css";

function App() {
  return (
    <main>
      <div className="tiles">
        <div className="tiles__line">
          <div
            className="tiles__line-img tiles__line-img--large"
            style={{ backgroundImage: "url(img/1l.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/1.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/2.jpg)" }}
          ></div>
          <div
            className="tiles__line-img tiles__line-img--large"
            style={{ backgroundImage: "url(img/2l.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/3.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/4.jpg)" }}
          ></div>
        </div>
        <div className="tiles__line">
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/5.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/6.jpg)" }}
          ></div>
          <div
            className="tiles__line-img tiles__line-img--large"
            style={{ backgroundImage: "url(img/3l.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/2.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/4.jpg)" }}
          ></div>
          <div
            className="tiles__line-img tiles__line-img--large"
            style={{ backgroundImage: "url(img/1l.jpg)" }}
          ></div>
        </div>
        <div className="tiles__line">
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/1.jpg)" }}
          ></div>
          <div
            className="tiles__line-img tiles__line-img--large"
            style={{ backgroundImage: "url(img/2l.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/3.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/5.jpg)" }}
          ></div>
          <div
            className="tiles__line-img tiles__line-img--large"
            style={{ backgroundImage: "url(img/3l.jpg)" }}
          ></div>
          <div
            className="tiles__line-img"
            style={{ backgroundImage: "url(img/2.jpg)" }}
          ></div>
        </div>
      </div>

      <nav className="menu">
        <a href="polskie.pdf" className="menu__item">
          <span className="menu__item-tiny">PL</span>
          <span className="menu__item-text">Menu</span>
        </a>
        <a href="polskie.pdf" className="menu__item">
          <span className="menu__item-text">Menu</span>
          <span className="menu__item-tiny">EN</span>
        </a>
        <a href="polskie.pdf" className="menu__item">
          <span className="menu__item-tiny">UKR</span>
          <span className="menu__item-text">Menu</span>
        </a>
      </nav>
    </main>
  );
}

export default App;
